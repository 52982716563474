import React, { useCallback, useContext, useEffect, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";

import { useLocation, useNavigation } from "@remix-run/react";

import type { Product } from "~/commerce-sap/.server/api/generated/__generated_apis";
import type { GTMEvent } from "~/components/types";
import { useSite } from "~/contexts";
import { useLoadScript } from "~/lib/utils/load-script";

export const PAGE_VIEW_EVENT = "page_view"; // default: "page_view", Bapcor specific: "page_view_ga4"
export const SEARCH_EVENT = "search";
export const VIEW_SEARCH_RESULT_EVENT = "view_search_result";
export const VIEW_ITEM_LIST_EVENT = "view_item_list_ga4"; // default: "view_item_list", Bapcor specific: "view_item_list_ga4"
export const SELECT_ITEM_EVENT = "select_item_ga4"; // default: "select_item", Bapcor specific: "select_item_ga4"
export const VIEW_ITEM_EVENT = "view_item_ga4"; // default: "view_item", Bapcor specific: "view_item_ga4"
export const ADD_TO_CART_EVENT = "add_to_cart_ga4"; // default: "add_to_cart", Bapcor specific: "add_to_cart_ga4"
export const REMOVE_FROM_CART_EVENT = "remove_from_cart_ga4"; // default: "remove_from_cart", Bapcor specific: "remove_from_cart_ga4"
export const VIEW_CART_EVENT = "view_cart";
export const BEGIN_CHECKOUT_EVENT = "begin_checkout_ga4"; // default: "begin_checkout", Bapcor specific: "begin_checkout_ga4"
export const ADD_SHIPPING_INFO_EVENT = "add_shipping_info_ga4"; // default: "add_shipping_info", Bapcor specific: "add_shipping_info_ga4"
export const ADD_PAYMENT_INFO_EVENT = "add_payment_info_ga4"; // default: "add_payment_info", Bapcor specific: "add_payment_info_ga4"
export const PURCHASE_EVENT = "purchase_ga4"; // default: "purchase", Bapcor specific: "purchase_ga4"

const ECOMMERCE_EVENTS = [
  VIEW_ITEM_LIST_EVENT,
  SELECT_ITEM_EVENT,
  VIEW_ITEM_EVENT,
  ADD_TO_CART_EVENT,
  REMOVE_FROM_CART_EVENT,
  VIEW_CART_EVENT,
  BEGIN_CHECKOUT_EVENT,
  ADD_SHIPPING_INFO_EVENT,
  ADD_PAYMENT_INFO_EVENT,
  PURCHASE_EVENT,
];

type CartEventType =
  | "add_to_cart_ga4"
  | "remove_from_cart_ga4"
  | "view_cart"
  | "begin_checkout_ga4"; // Bapcor specific values

const dataLayerQueue: GTMEvent[] = [];
let executingDataPush = false;

type GtmStateType = {
  pageViewSent: boolean;
};
type SetGtmStateType = React.Dispatch<React.SetStateAction<GtmStateType>>;

type CategoryHierarchyType = {
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
};

export type SimpleProduct = {
  code?: string;
  name?: string;
  price?: {
    value?: number;
    currencyIso?: string;
  };
  brand?: string | null;
  discountedPrice?: number | null;
  index?: number;
  list_id?: string;
  list_name?: string;
  quantity?: number;
  categories?: Array<{
    name?: string;
  }>;
};

type GTMProduct = {
  item_id: string;
  item_name: string;
  item_brand: string;
  discount?: number | null;
  price?: number;
  index?: number;
  list_id?: string;
  list_name?: string;
  quantity?: number;
  affiliation: string;
  item_variant: string;
} & CategoryHierarchyType;

const defaultGtmContext: {
  gtmState: GtmStateType;
  setGtmState: SetGtmStateType;
} = {
  gtmState: {
    pageViewSent: false,
  },
  setGtmState: () => {},
};

export const GTMContext = React.createContext(defaultGtmContext);

function cleanUpEvent(obj: GTMProduct) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != undefined),
  );
}

const dataLayerCallback = () => {
  executingDataPush = false;

  if (dataLayerQueue.length > 0) {
    const data = dataLayerQueue.shift();

    if (data) sendToGTM(data);
  }
};

const sendToGTM = (data: GTMEvent) => {
  if (window.dataLayer) {
    if (!data) return;

    // if the event execution is not complete by 2s then trigger the eventCallback either way
    // used for safety in case something happens, so that we can continue triggering GTM events
    data.eventTimeout = 2000;
    data.eventCallback = dataLayerCallback.bind(this);

    if (executingDataPush) {
      dataLayerQueue.push(data);

      return;
    }

    executingDataPush = true;
    if (ECOMMERCE_EVENTS.indexOf(data.event) > -1) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(data);
  } else {
    console.warn(
      `dataLayer is not initilalized yet, pushing event to queue`,
      data,
    );
    dataLayerQueue.push(data);

    const timer = setInterval(() => {
      if (window.dataLayer) {
        clearInterval(timer);

        dataLayerCallback();
      }
    });
  }
};

export type TransformProductsParams = {
  products: Array<Product>;
  list?: string;
  startIndex?: number;
};

function getCategoryHierarchy(product: SimpleProduct) {
  const categories: CategoryHierarchyType = {};

  product.categories?.slice(0, 5).forEach((category, index) => {
    const key = `item_category${
      index > 0 ? index + 1 : ""
    }` as keyof CategoryHierarchyType;
    categories[key] = category.name;
  });

  return categories;
}

export const useGTMTracker = () => {
  const getGtmProductInfo = useCallback((productId: string) => {
    try {
      const gtmProductInfo = JSON.parse(
        localStorage.getItem("gtmProductInfo") ?? "{}",
      );

      if (productId in gtmProductInfo) {
        return gtmProductInfo[productId];
      }
    } catch (e) {
      console.error("Error parsing GTM product info", e);
    }

    return null;
  }, []);

  const saveGTMProductInfo = useCallback(
    (
      product: Product,
      list_id: string,
      list_name: string,
      index: number = 0,
    ) => {
      try {
        const gtmProductInfo = JSON.parse(
          localStorage.getItem("gtmProductInfo") ?? "{}",
        );

        if (product && "code" in product) {
          gtmProductInfo[product.code as string] = {
            list_id,
            list_name,
            index,
          };

          localStorage.setItem(
            "gtmProductInfo",
            JSON.stringify(gtmProductInfo),
          );
        }
      } catch (e) {
        console.error("Error parsing GTM product info", e);
      }
    },
    [],
  );

  const trackEvent = useCallback((eventData: GTMEvent) => {
    sendToGTM(eventData);
  }, []);

  const transformProducts = useCallback(
    (products: Array<SimpleProduct>, index_start: number = 0) => {
      return products.map((product, index) => {
        const info = getGtmProductInfo(product.code ?? "");

        return cleanUpEvent({
          item_id: product.code ?? "",
          item_name: product.name ?? "",
          item_brand: product.brand ?? "",
          ...getCategoryHierarchy(product),
          discount: product.discountedPrice,
          price: product.price?.value,
          list_id: info?.list_id,
          list_name: info?.list_name,
          index: info?.index ? info.index : index + index_start + 1,
          quantity: product?.quantity,

          // Hardcoded values for Autobarn
          affiliation: "Autobarn Online",
          item_variant: "",
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const viewItemListEvent = useCallback(
    (
      products: Array<SimpleProduct>,
      index_start: number = 0,
      list_id: string,
      list_name: string,
    ) => {
      trackEvent({
        event: VIEW_ITEM_LIST_EVENT,
        ecommerce: {
          item_list_id: list_id,
          item_list_name: list_name,
          items: transformProducts(products, index_start),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const selectItemEvent = useCallback(
    (
      product: SimpleProduct,
      index: number = 0,
      list_id: string,
      list_name: string,
    ) => {
      trackEvent({
        event: SELECT_ITEM_EVENT,
        ecommerce: {
          item_list_id: list_id,
          item_list_name: list_name,
          items: transformProducts([product], index),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const viewItemEvent = useCallback(
    (product: SimpleProduct, index: number = 0) => {
      trackEvent({
        event: VIEW_ITEM_EVENT,
        ecommerce: {
          items: transformProducts([product], index),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const cartEventsEvent = useCallback(
    (event: CartEventType, products: Array<SimpleProduct>) => {
      trackEvent({
        event: event,
        ecommerce: {
          currency: products?.[0]?.price?.currencyIso ?? "AUD",
          value: products.reduce(
            (acc, product) =>
              acc + (product.price?.value ?? 0) * (product.quantity ?? 1),
            0,
          ),
          items: transformProducts(products),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const addShippingInfoEvent = useCallback(
    (products: Array<SimpleProduct>, shippingTier: string) => {
      trackEvent({
        event: ADD_SHIPPING_INFO_EVENT,
        ecommerce: {
          currency: products?.[0].price?.currencyIso ?? "AUD",
          value: products.reduce(
            (acc, product) =>
              acc + (product.price?.value ?? 0) * (product.quantity ?? 1),
            0,
          ),
          shipping_tier: shippingTier,
          items: transformProducts(products),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const addPaymentInfoEvent = useCallback(
    (products: Array<SimpleProduct>, paymentType: string) => {
      trackEvent({
        event: ADD_PAYMENT_INFO_EVENT,
        ecommerce: {
          currency: products?.[0].price?.currencyIso ?? "AUD",
          value: products.reduce(
            (acc, product) =>
              acc + (product.price?.value ?? 0) * (product.quantity ?? 1),
            0,
          ),
          payment_type: paymentType,
          items: transformProducts(products),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const purchaseEvent = useCallback(
    (
      order_number: string,
      products: Array<SimpleProduct>,
      shipping_amount: number,
      tax_amount: number,
    ) => {
      trackEvent({
        event: PURCHASE_EVENT,
        ecommerce: {
          affiliation: "Autobarn Online", // Hardcoded for Autobarn's website
          transaction_id: order_number,
          currency: products?.[0].price?.currencyIso ?? "AUD",
          value: products.reduce(
            (acc, product) =>
              acc + (product.price?.value ?? 0) * (product.quantity ?? 1),
            0,
          ),
          shipping: shipping_amount,
          tax: tax_amount,
          coupon: "", // TODO
          items: transformProducts(products),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  return {
    trackEvent,
    transformProducts,
    viewItemListEvent,
    selectItemEvent,
    viewItemEvent,
    cartEventsEvent,
    addShippingInfoEvent,
    addPaymentInfoEvent,
    purchaseEvent,
    saveGTMProductInfo,
  };
};

export const GTM = () => {
  const { site } = useSite();
  const location = useLocation();
  const navigation = useNavigation();
  const { setGtmState } = useContext(GTMContext);
  const { trackEvent } = useGTMTracker();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    const timer = setInterval(() => {
      window.dataLayer = window.dataLayer.slice(-100);
    }, 60_000 * 5);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (navigation.state === "idle") {
      trackEvent({ event: PAGE_VIEW_EVENT });

      setGtmState({ pageViewSent: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, navigation.state]);

  useLoadScript(
    `https://www.googletagmanager.com/gtm.js?id=${site.gtmContainer}`,
  );

  return <></>;
};

export const GTMProvider = ({ children }: { children: React.ReactNode }) => {
  const [gtmState, setGtmState] = useState({
    pageViewSent: false,
  });

  return (
    <GTMContext.Provider value={{ gtmState, setGtmState }}>
      <ClientOnly>{() => <GTM />}</ClientOnly>
      {children}
    </GTMContext.Provider>
  );
};
